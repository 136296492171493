import { EventType } from "../model/eventtype";

export const mapEventTypeToImage = function (eventType: EventType) {
	switch (eventType) {
		case EventType.NewDiaper:
		case EventType.ReuseDiaper:
			return "ok.svg";
		case EventType.DiaperFull:
			return "ful-wet.svg";
		case EventType.FirstUrine:
			return "wet-started.svg";
		case EventType.MaximumWearTime:
			return "max-time.svg";
		case EventType.BatteryLevel:
			return "low-battery.svg";
		case EventType.MaximumNoWearTime:
		case EventType.ChargingStarted:
		case EventType.ChargingFull:
		case EventType.LidOpen:
		case EventType.ChargingStopped:
			return "note.svg";
		case EventType.NoDataFromDevice:
		case EventType.NB_RSSILevel:
			return "nosignal.svg";
		case EventType.SensorSignalTooLow:
		case EventType.SensorSignalTooHigh:
		case EventType.SensorSignalGrass:
		case EventType.SensorSignalNotValid:
		case EventType.NB_SNRLevel:
		case EventType.NB_RSRPLevel:
			return "signalissue.svg";
		case EventType.DeviceTemperatureLow:
		case EventType.DeviceTemperatureHigh:
			return "temp.svg";
		case EventType.OutOfGeoPosition:
			return "geoloc.svg";
		case EventType.ConnectionReEstablished:
			return "reconnected.svg";
		default:
			return `${eventType.toString()}.svg`;
	}
}
