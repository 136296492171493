import { Component, OnInit } from '@angular/core';
import { ToastrNotification } from 'src/app/shared/model/toastrNotification';

@Component({
    selector: 'notification-toast',
    templateUrl: 'toast.component.html',
    styleUrls: ['./toast.component.scss']
})

export class ToastrComponent implements OnInit {
    toastrNotifications: ToastrNotification[] = [];
    timeOut: number = 15000;

    ngOnInit() {
        this.initTimeout();
    }

    notify(toastrNotification: ToastrNotification) {
        toastrNotification.timestamp = new Date().getTime() + this.timeOut;
        this.toastrNotifications.unshift(toastrNotification);
    }

    initTimeout() {
        const _this = this;
        setInterval(() => {
            const nowTimestamp = new Date().getTime();
            const expiredIndexes: number[] = [];

            for (let i = 0; i < _this.toastrNotifications.length; i++) {
                if ((nowTimestamp - _this.toastrNotifications[i].timestamp) >= 0) {
                    expiredIndexes.push(i);
                }
            }

            for (let j = 0; j < expiredIndexes.length; j++) {
                _this.toastrNotifications.splice(expiredIndexes[j], 1);
            }
        }, 100);
    }

    close(index: number) {
        this.toastrNotifications.splice(index, 1);
    }
}