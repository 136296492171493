import "reflect-metadata";

export const metadataKey = "configuration:anotations";

export default class Roles {
	public static readonly Admin = 'Admin';
	public static readonly Administrator = 'Administrator';
	public static readonly User = 'User';
	public static readonly ResidentViewer = 'ResidentViewer';
	public static readonly ResidentEditor = 'ResidentEditor';
	public static readonly CarehomeEditor = 'CarehomeEditor';
}

export function globalAdmin(target: Object, propertyKey: string | symbol) {
	Reflect.defineMetadata(metadataKey, Roles.Admin, target, propertyKey);
}

export function careHomeAdmin(target: Object, propertyKey: string | symbol) {
	Reflect.defineMetadata(metadataKey, Roles.Administrator, target, propertyKey);
}

export function getDecorators(target: any, propertyName: string | symbol): string[] {
	// get info about keys that used in current property
	const keys: any[] = Reflect.getMetadataKeys(target, propertyName);

	const decorators = keys
		.filter(key => key.toString() == metadataKey)
		.reduce((values, key) => {
			// get metadata value.
			const currValues = Reflect.getMetadata(key, target, propertyName);
			return values.concat(currValues);
		}, []);

	return decorators;
}