import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    title = 'Wear&Care';
    constructor(translate: TranslateService) {
        translate.setDefaultLang('en');
        if (localStorage.getItem('language')) {
            translate.use(localStorage.getItem('language'));
        }
    }
}
