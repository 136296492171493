import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { InterceptorNotificationService } from '../services/interceptor.notification.service';

@Injectable()
export class HandleErrorsInterceptors implements HttpInterceptor {

  neglectedRoutes = ["register", "logout"];
  neglectedRequests = ["api/device/search", "upsertcarehomedevice"];

  constructor(private notificationService: InterceptorNotificationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return new Observable((observer) => {
      next.handle(request).subscribe(
        (response: any) => {
          if (response.status) {
            observer.next(response);

            if (response.status && request.method !== "GET" && !this.isNeglectedRoute(request.url)) {
              this.notificationService.handleSuccess(response);
            }
          }
        },
        (error) => {
          console.log('error', error);
          if (!this.ignoredRequests(request.url) &&
            !(error.url.includes("api/user") && error.status === 401)) {
            this.notificationService.handleError(error);
          }
          observer.error(error);
        });
    });
  }

  isNeglectedRoute(url: string) {
    for (let i = 0; i < this.neglectedRoutes.length; i++) {
      if (url.toLocaleLowerCase().includes(this.neglectedRoutes[i])) {
        return true;
      }
    }

    return false;
  }

  ignoredRequests(url: string) {
    for (let i = 0; i < this.neglectedRequests.length; i++) {
      if (url.toLocaleLowerCase().includes(this.neglectedRequests[i])) {
        return true;
      }
    }

    return false;
  }

}