import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Department } from '../model/department';
import { HttpHelperService } from './httphelper.service';
import { DepartmentCareHomePair } from '../model/departmentCareHomePair';

const API_URL = environment.apiUrl;

@Injectable()
export class DepartmentService {
    constructor(private http: HttpClient, protected httpHelperService: HttpHelperService) {
    }

    getById(id: string) {
        return this.http.get<Department>(`${API_URL}department/${id}`, this.httpHelperService.getHttpOptions());
    }

    get(active?: boolean) {
        const queryString = active ? `?active=${active}` : "";
        return this.http.get<Department[]>(`${API_URL}department${queryString}`, this.httpHelperService.getHttpOptions());
    }

    create(department: Department) {
        return this.http.post<Department>(`${API_URL}department`, department, this.httpHelperService.getHttpOptions());
    }

    update(department: Department, id: string) {
        return this.http.post<Department>(`${API_URL}department/${id}`, department, this.httpHelperService.getHttpOptions());
    }

    persistCurrentDepartmentId(departmentId: string) {
        const careHomeId = localStorage.getItem("carehome-id");
        const departments = localStorage.getItem("departmentId");
        const departmentCareHomePair = this.deserialize(departments);

        if (!departmentCareHomePair) {
            const stringfiedDepartment = JSON.stringify([{
                departmentId: departmentId,
                careHomeId: careHomeId
            }]);

            localStorage.setItem('departmentId', stringfiedDepartment);
            return;
        }

        const existingPair = departmentCareHomePair.find(x => x.careHomeId == careHomeId && x.departmentId == departmentId);

        if (existingPair) {
            return;
        }

        const currentCepartmentCareHomePair = departmentCareHomePair.find(x => x.careHomeId == careHomeId);

        if (currentCepartmentCareHomePair) {
            currentCepartmentCareHomePair.departmentId = departmentId;
        } else {
            departmentCareHomePair.push({
                departmentId: departmentId,
                careHomeId: careHomeId
            });
        }

        localStorage.setItem('departmentId', JSON.stringify(departmentCareHomePair));
    }

    getCurrentDepartmentId(): string {
        const careHomeId = localStorage.getItem("carehome-id");
        const departments = localStorage.getItem('departmentId');
        const departmentCareHomePairs = this.deserialize(departments);

        if (!departmentCareHomePairs) {
            return null;
        }

        const currentCepartmentCareHomePair = departmentCareHomePairs.find(x => x.careHomeId == careHomeId);

        if (!currentCepartmentCareHomePair) {
            return null;
        }

        return currentCepartmentCareHomePair.departmentId;
    }

    deserialize(value: string) {
        try {
            return <DepartmentCareHomePair[]>JSON.parse(value);
        } catch (error) {
            return null;
        }
    }

    SetDepartmentId(departmentId: string) {
        let url = `${API_URL}PushSubscriptions/SetDepartmentId`;

        if (departmentId != "All") {
            url = `${url}/${departmentId}`
        }

        return this.http.post(url, {}, this.httpHelperService.getHttpOptions());
    }
}
