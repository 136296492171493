import { Routes } from '@angular/router';

// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
import { CanDeactivateGuard } from './shared/services/candeactivate.guard.service';
import { NgModule } from '@angular/core';
import { PrivacyLayoutComponent } from './common/privacy/common-privacy.component';
import { HomeComponent } from './modules/authentication/home/home.component';


export const AppRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
    },
    {
        path: 'privacy',
        component: PrivacyLayoutComponent
    },
    {
        path: '',
        component: AuthenticationLayoutComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: './modules/authentication/authentication.modules#AuthenticationModule'
            },
            {
                path: 'confirmation',
                loadChildren: './modules/confirmation/confirmation.module#ConfirmationModule'
            },
        ]
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: [
            {
                path: 'citizen',
                loadChildren: './modules/citizen/citizen.module#CitizenModule'
            },
            {
                path: 'carehome',
                loadChildren: './modules/carehome/carehome.module#CareHomeModule'
            },
            {
                path: 'settings',
                loadChildren: './modules/settings/settings.module#SettingsModule'
            },
            {
                path: 'user',
                loadChildren: './modules/user/user.module#UserModule'
            },
            {
                path: 'reports',
                loadChildren: './modules/reports/reports.module#ReportsModule'
            },
            {
                path: 'diaper',
                loadChildren: './modules/diaper/diaper.module#DiaperModule'
            },
            {
                path: 'statusoverview',
                loadChildren: './modules/statusoverview/status-overview.module#StatusOverviewModule'
            },
            {
                path: 'carehomes',
                loadChildren: './modules/carehomes/carehomes.module#CareHomesModule'
            },
            {
                path: 'userManagement',
                loadChildren: './modules/usermanagement/user-management.module#UserManagementModule'
            },
            {
                path: 'eventConfiguration',
                loadChildren: './modules/eventconfiguration/event-configuration.module#EventConfigurationModule'
            },
            {
                path: 'eventGenerating',
                loadChildren: './modules/event-generating/event-generating.module#EventGeneratingModule'
            }

        ]
    }
];

@NgModule({
    providers: [
        CanDeactivateGuard
    ]
})
export class AppRoutesModule { }

