import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class HttpHelperService {
    constructor(private http: HttpClient) {
    }

    getHeaders() {
        const headers = {
            'Content-Type': 'application/json'
        };
        if (localStorage.getItem('carehome-id')) {
            headers['carehome-id'] = localStorage.getItem('carehome-id');
        }
        return headers;
    }

    getHttpOptions() {
        const headers = this.getHeaders();
        return {
            headers: new HttpHeaders(headers),
            observe: 'response' as 'response',
            withCredentials: true
        };
    }

    public getCareHomeId() {
        return localStorage.getItem('carehome-id');
    }

    public setCarehomeId(carehomeId: string) {
        localStorage.setItem('carehome-id', carehomeId);
    }

    public clearCarehomeId() {
        localStorage.removeItem('carehome-id');
    }

    public getHttpUploadHeader() {
        return new HttpHeaders({
            'Authorization': 'Bearer ' + sessionStorage.getItem('auth_token')
        });
    }

    public getHttpOptionsWithParams(params: HttpParams) {
        const headers = this.getHeaders();
        return {
            headers: new HttpHeaders(headers),
            observe: 'response' as 'response',
            params: params
        };
    }
}
