import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  constructor(private authService: AuthService, private userService: UserService, private router: Router) { 
    this.userService.getUserDetails().subscribe(data =>{
      this.router.navigate([this.authService.setCallbackURL()]);
    }, err => {
      if (err.status !== 200) {
        this.authService.clearSession();
        this.authService.clearLocalStorage();
        this.router.navigate(['authentication/signin']);
      }
    });
  }

  ngOnInit() {}

}
