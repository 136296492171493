import { environment } from "src/environments/environment";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHelperService } from './httphelper.service';

const API_URL = environment.apiUrl;

@Injectable()
export class ServiceWorkerRegisteration {
  constructor(private http: HttpClient, protected httpHelperService: HttpHelperService) {
  }

  arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  }

  registerSubscriptionData(sub) {
    const subscriptionData = {
      endpoint: sub.endpoint,
      key: this.arrayBufferToBase64(sub.getKey("p256dh")),
      secret: this.arrayBufferToBase64(sub.getKey("auth"))
    };

    return this.registerPushNotificationSubscription(subscriptionData);
  }

  getSubscription(reg) {
    const _this = this;
    this.getWePushKey().subscribe(data => {
      reg.pushManager.getSubscription().then(function (sub) {
        reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: data.body.Key
        }).then(function (sub) {
          _this.registerSubscriptionData(sub);
        }).catch(function (error) {
          console.error("Unable to subscribe to push", error);
        });
      });
    });
  }

  initializeServiceWorker() {
    if (!('serviceWorker' in navigator)) {
      return;
    }

    if (!('PushManager' in window)) {
      return;
    }

    const _this = this;
    navigator.serviceWorker.register("/assets/config/serviceworker.js")
      .then(reg => {
        Notification.requestPermission(function (status) {
          if (status == "granted") {
            _this.getSubscription(reg);
          }
        });
      })
      .catch(error => {
        console.error('error', error);
      })
  }

  toggleNotificationSubscribtion(isDisabled: boolean) {
    return this.http.post<any>(`${API_URL}PushSubscriptions/ToggleNotificationSubscribtion`, isDisabled, this.httpHelperService.getHttpOptions()).subscribe(data => {
    });
  }

  getNotificationSubscription() {
    return this.http.get<any>(`${API_URL}PushSubscriptions/GetNotificationSubscription`, this.httpHelperService.getHttpOptions());
  }

  getWePushKey() {
    return this.http.get<any>(`${API_URL}PushSubscriptions/GetWebPushKey`, this.httpHelperService.getHttpOptions());
  }

  registerPushNotificationSubscription(subscriptionData: any) {
    this.http
      .post<any>(`${API_URL}PushSubscriptions/register`, subscriptionData, this.httpHelperService.getHttpOptions())
      .subscribe(data => { });
  }
}