import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../shared/services/auth.service';
import { AccountService } from '../shared/services/account.service';
import { UserRoles } from '../shared/model/userrole';
import { ErrorService } from '../shared/services/error.service';
import { SignalrService } from '../shared/services/signalR.service';
import { EventNotficationMessage } from '../shared/model/eventNotficationMessage';
import { AudioService } from '../shared/services/audioService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServiceWorkerRegisteration } from '../shared/services/service.worker.registeration';
import { ToastrComponent } from './toastr/toast.component';
import { ToastrNotification } from '../shared/model/toastrNotification';
import { mapEventTypeToImage } from '../shared/Utilities/eventTypeImageConverter';
import { EventType } from '../shared/model/eventtype';
import { DepartmentService } from '../shared/services/department.service';
import Roles from '../shared/model/user/Roles';
import { getFormattedDate } from '../shared/Utilities/timeFormatter';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './common-layout.component.html',
    styleUrls: ['./common-layout.component.scss']
})

export class CommonLayoutComponent implements OnInit, OnDestroy {
    @ViewChild(ToastrComponent) toastr: ToastrComponent;

    public app: any;
    public headerThemes: any;
    public changeHeader: any;
    public sidenavThemes: any;
    public changeSidenav: any;
    public headerSelected: any;
    public sidenavSelected: any;
    public searchActived: any;
    public searchModel: any;
    public isDisabledNotification: boolean;
    notificationSubscription: Subscription;
    subscriptionData = {
        endpoint: null,
        key: null,
        secret: null
    };

    isAdmin = false;
    routerLink = '';
    copyRightText = '';
    offlineDate: Date | null = null;

    constructor(public router: Router,
        private translate: TranslateService,
        public authService: AuthService,
        private accountService: AccountService,
        private errorService: ErrorService,
        private signalRService: SignalrService,
        private departmentService: DepartmentService,
        private changeDetectorRef: ChangeDetectorRef,
        private audioService: AudioService,
        private serviceWorkerRegisteration: ServiceWorkerRegisteration) {
        this.app = {
            layout: {
                sidePanelOpen: false,
                isMenuOpened: true,
                isMenuCollapsed: false,
                themeConfigOpen: false,
                rtlActived: false,
                searchActived: false
            }
        };

        this.translate.use(this.authService.getLanguage());
        this.headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
        this.changeHeader = changeHeader;

        function changeHeader(headerTheme) {
            this.headerSelected = headerTheme;
        }

        this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
        this.changeSidenav = changeSidenav;

        function changeSidenav(sidenavTheme) {
            this.sidenavSelected = sidenavTheme;
        }

        if (this.authService.userHasRole(Roles.Admin)) {
            this.routerLink = '/carehomes';
        } else {
            this.routerLink = '/statusoverview/statusoverview';
        }
    }

    ngOnInit() {
        this.askForPlayAudioPermission();
        this.getNotificationSubscription();
        this.addWebPushRegisteration();
        this.validateUserRole();
        this.addNetworkEventListeners();
        this.copyRightText = '© ' + (new Date()).getFullYear().toString() + ' ';
    }

    notifyUser(message: EventNotficationMessage) {
        this.toastr.notify(new ToastrNotification({
            body: `${message.residentName} at ${(new Date(message.occurrence)).toLocaleString()}`,
            header: message.notificationText,
            icon: `assets/images/icons/${mapEventTypeToImage(EventType[message.eventType])}`
        }));
    }

    ngOnDestroy() {
        this.notificationSubscription.unsubscribe();

        if (this.router.url.toLowerCase().indexOf('signin') > -1) {
            Swal.close()
        }
    }

    logOut() {
        this.signalRService.disconnect()
            .then(() => {
                this.authService.logout()
                    .subscribe(data => {
                        this.authService.clearSession();
                        this.authService.clearLocalStorage();
                        window.location.href = '/authentication/signin';
                    }, (error): void => {
                        console.error(error);
                    });
            }).catch(console.error);

        return false;
    }

    validateUserRole() {
        this.isAdmin = false;
    }

    addSignalR() {
        this.signalRService.startConnection();
        this.signalRService.subscribeToEventsUpdate();

        this.notificationSubscription = this.signalRService.eventNotficationMessage.subscribe((message: EventNotficationMessage) => {
            const departmentId = this.departmentService.getCurrentDepartmentId();

            if (message && (departmentId == message.departmentId || departmentId == "All" || !departmentId) && !this.isDisabledNotification) {
                this.notifyUser(message);
                this.audioService.playNotificationSound();
            }
        });
    }

    askForPlayAudioPermission() {
        return;
        if (this.audioService.playAudioApproved) {
        }

        Swal.fire({
            text: "Allow events notification sound",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.value) {
                this.audioService.approvePlayAudio(result.value);
            } else {
                this.audioService.approvePlayAudio(false);
            }
        });
    }

    addWebPushRegisteration() {
        if (this.authService.userHasRole(Roles.Admin)) {
            return;
        }

        const userRoles = this.authService.getUserRoles();

        if (userRoles.indexOf(UserRoles[UserRoles.Admin]) > -1 && userRoles.length == 1) {
            return;
        }

        this.serviceWorkerRegisteration.registerPushNotificationSubscription(this.subscriptionData);
        this.serviceWorkerRegisteration.initializeServiceWorker();
    }

    detectChanges() {
        if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
            this.changeDetectorRef.detectChanges();
        }
    }

    addNetworkEventListeners() {
        window.addEventListener("online", () => {
            this.offlineDate = null;
        });

        window.addEventListener("offline", () => {
            this.offlineDate = new Date();
        });
    }

    getFormattedOfflineDate(): string {
        return getFormattedDate(this.offlineDate);
    }

    getFormattedOfflineTime(): string {
        return this.offlineDate.toLocaleTimeString();
    }

    disableNotificationToggle() {
        this.isDisabledNotification = !this.isDisabledNotification;
        this.serviceWorkerRegisteration.toggleNotificationSubscribtion(this.isDisabledNotification);
    }

    getNotificationSubscription() {
        if (this.authService.userHasRole(Roles.Admin)) {
            return;
        }

        this.serviceWorkerRegisteration
            .getNotificationSubscription()
            .subscribe(data => {
                this.isDisabledNotification = data.body.Disabled;
                this.addSignalR();
            }, error => console.error(error));
    }

    isGlobalAdmin() : boolean{
        return this.authService.userHasRole(Roles.Admin);
    }
}