import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { User } from '../model/user/user';
import { UserResult } from "../model/user/UserResult";
import { UserPassword } from '../model/userpassword';
import { HttpHelperService } from './httphelper.service';
import { API_URL } from './account.service';

@Injectable()
export class ProfileService {
	constructor(public router: Router, private http: HttpClient, private httpHelperService: HttpHelperService) {
	}

	getProfile() {
		return this.http.get<User>(`${API_URL}profile`, this.httpHelperService.getHttpOptions());
	}

	updateGeneralInfo(user: User) {
		return this.http.post<UserResult>(`${API_URL}profile/generalinfo`, user, this.httpHelperService.getHttpOptions());
	}

	updateLanguage(language: string) {
		return this.http.post<boolean>(`${API_URL}profile/language?lang=${language}`, {}, this.httpHelperService.getHttpOptions());
	}

	updatePassword(userPassword: UserPassword) {
		return this.http.post<boolean>(`${API_URL}profile/password`, userPassword, this.httpHelperService.getHttpOptions());
	}
}
