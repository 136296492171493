import Roles from '../model/user/Roles';
import RolesHierarchy from '../model/user/RolesHierarchy';
import { AuthService } from './../services/auth.service';
import { Directive, Input, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[userlevel]'
})
export class UserLevelDirective implements OnInit {
    constructor(private element: ElementRef, private renderer: Renderer2, private authService: AuthService) {
    }

    @Input() minimumRole: string = '';
    @Input() minimumAdminRole: string = '';

    ngOnInit(): void {
        const userRoles = this.authService.getUserRoles();
        const accessRoleLevel = RolesHierarchy.get(this.minimumRole);
        const adminAccessRoleLevel = RolesHierarchy.get(this.minimumAdminRole);
        const userRoleLevel = this.getMaxRoleLevel(userRoles);

        if (userRoles.includes(Roles.Admin)) {
            this.handleUserRole(userRoleLevel, adminAccessRoleLevel);
        } else {
            this.handleUserRole(userRoleLevel, accessRoleLevel);

        }
    }

    handleUserRole(userRoleLevel: number, accessRoleLevel) {
        if (userRoleLevel >= accessRoleLevel) {
            this.renderer.setStyle(this.element.nativeElement, 'display', '');
        } else {
            this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
        }
    }

    getMaxRoleLevel = (userRoles: string[]): number =>
        userRoles.reduce((maxRoleLevel, role) => Math.max(maxRoleLevel, RolesHierarchy.get(role) || 0), 0);

    isGlobalAdminRoles(): boolean {
        return this.minimumAdminRole.length > 0;
    }
}
