import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResidentData } from '../model/citizendata';
import { CreateResidentRequest, Resident, ResidentResult, UpdateResidentRequest } from '../model/citizen';
import { HttpHelperService } from './httphelper.service';

const API_URL = environment.apiUrl;

@Injectable()
export class ResidentService {
  constructor(private http: HttpClient, private httpHelperService: HttpHelperService) {
  }

  getAll() {
    return this.http.get<ResidentData[]>(`${API_URL}resident`, this.httpHelperService.getHttpOptions());
  }

  getById(id: string) {
    return this.http.get<Resident>(`${API_URL}resident/${id}`, this.httpHelperService.getHttpOptions());
  }

  add(resident: CreateResidentRequest) {
    return this.http.post<ResidentData>(`${API_URL}resident`, resident, this.httpHelperService.getHttpOptions());
  }

  update(resident: UpdateResidentRequest, id: string) {
    return this.http.post<ResidentResult>(`${API_URL}resident/${id}`, resident, this.httpHelperService.getHttpOptions());
  }

  delete(residentId) {
    return this.http.delete(`${API_URL}resident/${residentId}`, this.httpHelperService.getHttpOptions());
  }
}
