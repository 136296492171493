import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorService {
    constructor(private translate: TranslateService, private router: Router, private toastyService: ToastyService) {
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            this.toastyService.error('An error occurred');
            console.error('An error occurred:', error.error.message);
        } else {
            if (error.status == 401 && this.router.url == "/authentication/signin") {
                let toastOptions = this.getToastOptions();
                toastOptions.msg = "Invalid username or password!";
                this.toastyService.error(toastOptions);
            } else if (error.status == 401) {
                let toastOptions = this.getToastOptions();
                toastOptions.msg = this.translate.instant('errors.sessionExpired');
                this.toastyService.error(toastOptions);
                window.location.href = '/authentication/signin';
            }
            else {
                this.toastyService.clearAll();
                console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
                let toastOptions = this.getToastOptions();
                toastOptions.msg = this.translate.instant('errors.generalError');
                this.toastyService.error(toastOptions);
            }
        }
    }

    private getToastOptions() {
        let toastOptions: ToastOptions = {
            title: '',
            msg: '',
            showClose: false,
            timeout: 5000,
            theme: "bootstrap"
        };
        return toastOptions;
    }
}