import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

// Layout Modules
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';

// Directives
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Sidebar_Directives } from './shared/directives/side-nav.directive';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

// Routing Module
import { AppRoutes, AppRoutesModule } from './app.routing';

// App Component
import { AppComponent } from './app.component';
import { AuthGuard } from './modules/authentication/auth.guard';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SharedModule } from './shared.module';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { ToastrComponent } from './common/toastr/toast.component';
import { HandleErrorsInterceptors } from './shared/interceptors/HandleErrorsInterceptors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { PrivacyLayoutComponent } from './common/privacy/common-privacy.component';
import { HomeComponent } from './modules/authentication/home/home.component';
import { DataTablesModule } from 'angular-datatables';

class TranslateHttpLoader implements TranslateLoader {
    constructor(private http: HttpClient,
        public prefix: string = '../assets/i18n/',
        public suffix: string = '.json',
        public cacheBustValue: string = '16') {
    }

    public getTranslation(lang: string): Observable<Object> {
        if (!lang) {
            debugger;
        }
        return this.http.get(`${this.prefix}${lang}${this.suffix}?cachebust=${this.cacheBustValue}`).pipe(
            catchError(_ => of({}))
        );
    }
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
@NgModule({
    imports: [
        BrowserModule,
        AppRoutesModule,
        DataTablesModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(AppRoutes, { useHash: true }),
        NgbModule,
        PerfectScrollbarModule,
        HttpClientModule,
        ToastrModule.forRoot({
            timeOut: 5000
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        SharedModule
    ],
    declarations: [
        AppComponent,
        CommonLayoutComponent,
        PrivacyLayoutComponent,
        AuthenticationLayoutComponent,
        ToastrComponent,
        Sidebar_Directives,
        HomeComponent
    ],
    providers: [
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HandleErrorsInterceptors,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
