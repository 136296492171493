export const getFormattedTime = function (seconds: number): string {
	let formattedTime = '';
	var days = Math.floor(seconds / (3600 * 24));
	var hours = Math.floor(seconds % (3600 * 24) / 3600);
	var minutes = Math.floor(seconds % 3600 / 60);
	var seconds = Math.floor(seconds % 60);

	if (days > 0) {
		formattedTime += `${days} days,`;
	}

	if (hours > 0) {
		formattedTime += ` ${hours} hours,`;
	}

	if (minutes > 0) {
		formattedTime += ` ${minutes} minutes,`;
	}

	if (seconds > 0) {
		formattedTime += ` ${seconds} seconds`;
	}

	if (formattedTime === '') {
		formattedTime = '-';
	}

	return formattedTime;
}

export const getFormattedDate = function (occurance: Date): string {
	const today = new Date().getDate();
	const occuranceDate = new Date(occurance);
	const occuranceDay = occuranceDate.getDate();

	if (occuranceDay == today) {
		return "general.today"
	}

	if (today - occuranceDay == 1) {
		return "general.yesterday";
	}

	return `${occuranceDay}-${occuranceDate.getMonth() + 1}-${occuranceDate.getFullYear()}`;
}
