import { Injectable } from '@angular/core';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { EventNotficationMessage } from '../model/eventNotficationMessage';
import { EventType } from '../model/eventtype';

@Injectable()

export class NotificationService {
    constructor(private toastyService: ToastyService) {
    }

    notifyEventsUpdate(message: EventNotficationMessage) {
        const toastOptions = this.getToastOptions(message);
        this.toastyService.info(toastOptions);
    }

    private getToastOptions(message: EventNotficationMessage) {
        const toastOptions: ToastOptions = {
            title: EventType[message.eventType],
            msg: `${message.residentName} at ${new Date(message.occurrence).toUTCString()}`,
            showClose: false,
            timeout: 15000,
            theme: "bootstrap"
        };
        return toastOptions;
    }
}