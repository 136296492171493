import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class InterceptorNotificationService {
  constMessageRoutes = ["togglenotificationsubscribtion"];

  constructor(private translate: TranslateService,
    private toastrService: ToastrService) {
  }

  handleError(error: HttpErrorResponse | string) {
    this.toastrService.error('Something went wrong');
  }

  handleErrorMessage(message: string) {
    this.toastrService.error(message);
  }

  handleSuccess(success: HttpErrorResponse) {
    const messageKey = this.constMessageRoutes.some(str => success.url.toLowerCase().includes(str)) ? 'messages.entitySaveSuccessfully' : 'messages.savedSuccessafully';

    this.translate
      .get(messageKey, { entity: "", name: "" })
      .subscribe((res: string) => {
        this.toastrService.success(res);
        this.scrollToTop();
      });
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}