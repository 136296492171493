import { Injectable } from '@angular/core';

const playAudioApprovedKey: string = "playAudioApproved";

@Injectable({
	providedIn: 'root'
})


export class AudioService {
	private audio;
	private _playAudioApproved: boolean = false;

	get playAudioApproved(): boolean {
		return this._playAudioApproved;
	}

	constructor() {
		this.audio = new Audio('../../../../assets/sound/notification.wav');
		this._playAudioApproved = JSON.parse(sessionStorage.getItem(playAudioApprovedKey));
	}

	public approvePlayAudio(approved: boolean) {
		this._playAudioApproved = approved;
		sessionStorage.setItem(playAudioApprovedKey, `${approved}`);
	}

	public playNotificationSound = () => {
		try {
			this.audio.play();
		} catch (error) {

		}
	}
}
