import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    selector: '[appPasswordPolicyValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: PasswordPolicyValidatorDirective,
        multi: true
    }]

})
export class PasswordPolicyValidatorDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: any } | null {
        const hasNumbers = /(?:\D*\d){2}/.test(control.value);
        const hasUpper = /[A-Z]/.test(control.value);
        const password: string = control.value;

        if (password == null || password == 'undefined' || password.length < 8 || !hasNumbers || !hasUpper) {
            return { 'invalid': true };
        }
        return null;
    }
}
