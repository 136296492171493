import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CareHome } from '../model/carehome';
import { HttpHelperService } from './httphelper.service';
import { FirmwareUpdateSettings } from '../model/firmwareupdatesettings';
import CareHomeModel from '../model/CareHomeModel';
import { DayNightHours } from '../model/daynighthours';
import { TunstallSettings } from '../model/tunstallsettings';
import { UserViewModel } from '../model/user/UserViewModel';

const API_URL = environment.apiUrl;

@Injectable()
export class CareHomeService {
    constructor(private http: HttpClient, private httpHelperService: HttpHelperService) {
    }

    getCarehome() {
        return this.http.get<CareHome>(`${API_URL}carehome`, this.httpHelperService.getHttpOptions());
    }

    updateCareHome(carehome: CareHome) {
        return this.http.post<CareHome>(`${API_URL}carehome`, carehome, this.httpHelperService.getHttpOptions());
    }

    getCareHomeDayNightHours() {
        return this.http.get<DayNightHours>(`${API_URL}carehome/dayNightHours`, this.httpHelperService.getHttpOptions());
    }

    updateCareHomeDayNightHours(dayNightHours: DayNightHours) {
        return this.http.post<boolean>(`${API_URL}carehome/dayNightHours`, dayNightHours, this.httpHelperService.getHttpOptions());
    }

    getTunstallNotificationSettings() {
        return this.http.get<TunstallSettings>(`${API_URL}carehome/tunstallsettings`, this.httpHelperService.getHttpOptions());
    }

    updateTunstallSettings(tunstallSettings: TunstallSettings) {
        return this.http.post<boolean>(`${API_URL}carehome/tunstallsettings`, tunstallSettings, this.httpHelperService.getHttpOptions());
    }

    // global admin
    get(id: string) {
        return this.http.get<CareHomeModel>(`${API_URL}carehomes/${id}`, this.httpHelperService.getHttpOptions());
    }

    getAll() {
        return this.http.get<CareHomeModel[]>(`${API_URL}carehomes`, this.httpHelperService.getHttpOptions());
    }

    getActiveList() {
        return this.http.get<CareHome[]>(`${API_URL}carehomes/active`, this.httpHelperService.getHttpOptions());
    }

    add(careHome: CareHomeModel) {
        return this.http.post<CareHomeModel>(`${API_URL}carehomes`, careHome, this.httpHelperService.getHttpOptions());
    }

    update(careHome: CareHomeModel) {
        return this.http.put<CareHomeModel>(`${API_URL}carehomes`, careHome, this.httpHelperService.getHttpOptions());
    }

    updateFirmwareUpdateSettings(firmwareSettings: FirmwareUpdateSettings, careHomeId: string) {
        return this.http.post<boolean>(`${API_URL}carehomes/firmwaresettings/` + careHomeId, firmwareSettings, this.httpHelperService.getHttpOptions());
    }
}
