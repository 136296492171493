export enum EventType {
	NewDiaper = 1,
	FirstUrine = 2,
	DiaperFull = 3,
	MaximumWearTime = 4,
	LidOpen = 5,
	MaximumNoWearTime = 6,
	AbnormalMotion = 7,
	OutOfGeoPosition = 8,

	AliveSignal = 9,
	ChargingStarted = 10,
	ChargingFull = 11,
	ChargingStopped = 12,


	SensorSignalTooLow = 13,
	SensorSignalNotValid = 14,
	SensorSignalTooHigh = 15,
	SensorSignalGrass = 16,
	ReuseDiaper = 17,


	NoDataFromDevice = 31,
	ConnectionReEstablished = 32,
	BatteryLevel = 33,
	NB_RSSILevel = 34,
	DeviceTemperatureLow = 35,
	DeviceTemperatureHigh = 36,
	NB_SNRLevel = 37,
	NB_RSRPLevel = 38
}
