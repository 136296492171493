import { CareHomeDetail } from './../model/carehome';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/retry';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/filter';
import 'rxjs';
import { UserBasicInfo } from "../model/user/UserBasicInfo";
import { environment } from '../../../environments/environment';
import { HttpHelperService } from './httphelper.service';
import { Observable } from 'rxjs';
import RolesHierarchy from '../model/user/RolesHierarchy';
import Roles from 'src/app/shared/model/user/Roles';

const API_URL = environment.apiUrl;

@Injectable()
export class AuthService {
    constructor(public router: Router, private http: HttpClient, private datepipe: DatePipe, private httpHelperService: HttpHelperService) {
    }

    login(email: string, password: string): Observable<HttpResponse<any>> {
        return this.http.post<any>(`${API_URL}auth/login`, { email, password }, this.httpHelperService.getHttpOptions());
    }

    logout(): Observable<HttpResponse<any>> {
        return this.http.post<any>(`${API_URL}auth/logout`, {}, this.httpHelperService.getHttpOptions());
    }

    VerifyCode(code: string): Observable<HttpResponse<UserBasicInfo>> {
        return this.http.post<UserBasicInfo>(`${API_URL}auth/VerifyCode?code=${code}`, {}, this.httpHelperService.getHttpOptions());
    }

    resendOtp() {
        return this.http.post<boolean>(API_URL + 'auth/resendotp', null, this.httpHelperService.getHttpOptions());
    }

    verifyEmail(email: string) {
        return this.http.post(`${API_URL}auth/verifyemail?email=${email}`, this.httpHelperService.getHttpOptions());
    }

    validateResetPasswordToken(token: string) {
        return this.http.post<boolean>(API_URL + 'auth/validateresettoken/' + token, this.httpHelperService.getHttpOptions());
    }

    resetPassword(token: string, password: string) {
        console.log(password);
        return this.http.post<boolean>(`${API_URL}auth/resetpassword`, { password: password, token: token }, this.httpHelperService.getHttpOptions());
    }

    public setLocalStorage(user: UserBasicInfo): void {
        localStorage.setItem('language', user.Language ? user.Language : "en");
        this.setUserRoles(user.Roles);
        this.setUserName(user.Fullname);
    }

    public extendSession() {
        const exp_At: string | null = sessionStorage.getItem('expires_at');
        const expire_extension: string | null = sessionStorage.getItem('expire_extension');
        if (exp_At == null || expire_extension == null) {
            return false;
        }
        const expire_extension_sec = parseInt(expire_extension, 10);
        const newExp = new Date(exp_At);
        newExp.setSeconds(newExp.getSeconds() + expire_extension_sec);
        sessionStorage.setItem('expires_at', newExp.toString());
    }

    public isAuthenticated(): boolean {
        // var exp_At: string | null = sessionStorage.getItem('expires_at');
        // var auth_token: string | null = sessionStorage.getItem('auth_token');

        // var exp;
        // if (exp_At == null || auth_token == null) {
        //     return false;
        // } else {
        //     exp = exp_At.toString();
        // }
        // const currentTime = this.datepipe.transform(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
        // const expiresAt = JSON.parse(exp);

        // TODO: 
        return true;
    }

    public clearSession(): void {
        sessionStorage.removeItem('expires_at');
        sessionStorage.removeItem('id_token');
        sessionStorage.removeItem('ch_id');
        sessionStorage.removeItem('type');
        sessionStorage.removeItem('expire_extension');
        sessionStorage.removeItem('user_level');
        sessionStorage.removeItem('carehome-id');
        sessionStorage.removeItem('playAudioApproved');
    }

    public clearLocalStorage(): void {
        localStorage.removeItem('language');
        localStorage.removeItem('user_name');
        localStorage.removeItem('token');
        localStorage.removeItem('user_roles');
        localStorage.removeItem('carehome-id');
    }

    public setUserId(userId: string) {
        sessionStorage.setItem('id_token', userId);
    }

    public getUserName(): string {
        return localStorage.getItem('user_name');
    }

    public getLanguage(): string {
        return localStorage.getItem('language') ? localStorage.getItem('language') : "en";
    }

    public getIdToken(): string {
        return sessionStorage.getItem('id_token');
    }

    public setUserType(type: string) {
        sessionStorage.setItem('type', btoa(type));
    }

    public getUserType() {
        return atob(sessionStorage.getItem('type'));
    }

    public setUserName(userName: string) {
        return localStorage.setItem('user_name', userName);
    }

    public validateAuthToken(): boolean {
        return localStorage.getItem('user_name') !== null;
    }

    public setUserValues(careHome: CareHomeDetail, type: string) {
        sessionStorage.setItem('type', btoa(type));
        sessionStorage.setItem('ch_id', careHome.CareHomeId);
    }

    public setUserRoles(roles: string[]): void {
        localStorage.setItem('user_roles', JSON.stringify(roles));
    }

    public getUserRoles(): string[] {
        return JSON.parse(localStorage.getItem('user_roles'));
    }

    public userHasRole(role: string): boolean {
        const userRoles = this.getUserRoles();
        return userRoles ? userRoles.includes(role) : false;
    }

    public setToken(token: string) {
        localStorage.setItem('token', token);
    }

    public getToken() {
        return localStorage.getItem('token');
    }

    public hasMinimumRole(role: string): boolean {
        const minimumRole = RolesHierarchy.get(role);
        const userMaximumRole = this.getUserMaximumRole();

        return userMaximumRole >= minimumRole;
    }

    public getUserMaximumRole(): number {
        const userRoles = this.getUserRoles();

        const maxRole = userRoles.reduce((maxRole, currentRole) => {
            if (RolesHierarchy.get(currentRole) > RolesHierarchy.get(maxRole)) {
                return currentRole;
            } else {
                return maxRole;
            }
        }, userRoles[0]);

        return RolesHierarchy.get(maxRole);
    }

    setCallbackURL() {
        if (this.userHasRole(Roles.Admin)) {
            return "/carehomes";
        }

        if (this.userHasRole(Roles.Administrator)
            || this.userHasRole(Roles.User)) {
            return "/statusoverview/statusoverview";
        }

        if (this.userHasRole(Roles.CarehomeEditor)) {
            return "/carehome/manage-carehome";
        }

        if (this.userHasRole(Roles.ResidentViewer)
            || this.userHasRole(Roles.ResidentEditor)) {
            return "/citizen/manage-citizens";
        }

        return "/authentication/signin";
    }
}