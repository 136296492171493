import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { UserBasicInfo } from "../model/user/UserBasicInfo";
import { UserViewModel } from "../model/user/UserViewModel";
import { environment } from '../../../environments/environment';
import { CareHomeDetail } from '../model/carehome';
import { HttpHelperService } from './httphelper.service';
import UserConfirmationModel from '../../modules/confirmation/user-confirmation/UserConfirmationModel';
import { CreateUserModel, EditUserModel } from '../model/user/CreateUserModel';
import Role from '../model/user/Role';
import SendInvitationModel from '../model/user/SendInvitationModel';
import ResetUserPasswordModel from '../model/user/ResetUserPasswordModel';

export const API_URL = environment.apiUrl;

@Injectable()
export class UserService {
	constructor(public router: Router, private http: HttpClient, private httpHelperService: HttpHelperService) {
	}

	get(id: string, careHomeId: string = null) {
		let url = `${API_URL}user/${id}`

		if (careHomeId) {
			url = `${url}?careHomeId=${careHomeId}`;
		}

		return this.http.get<UserViewModel>(url, this.httpHelperService.getHttpOptions());
	}

	getUserDetails() {
		return this.http.get<UserViewModel[]>(`${API_URL}user`, this.httpHelperService.getHttpOptions());
	}

	add(user: CreateUserModel) {
		return this.http.post<any>(`${API_URL}user`, user, this.httpHelperService.getHttpOptions());
	}

	edit(user: EditUserModel) {
		return this.http.put<any>(`${API_URL}user`, user, this.httpHelperService.getHttpOptions());
	}

	delete(userId: string) {
		const url = `${API_URL}user?userId=${userId}`;
		return this.http.delete<any>(url, this.httpHelperService.getHttpOptions());
	}

	deleteCareHomeUser(userId: string, careHomeId: string) {
		const url = `${API_URL}user/DeleteCareHomeUser?userId=${userId}&careHomeId=${careHomeId}`;
		return this.http.delete<any>(url, this.httpHelperService.getHttpOptions());
	}

	getAllRoles() {
		return this.http.get<Role[]>(`${API_URL}user/roles`, this.httpHelperService.getHttpOptions());
	}

	getCareHomeUsers(careHomeId: string) {
		return this.http.get<UserViewModel[]>(`${API_URL}user/GetCareHomeUsers?careHomeId=${careHomeId}`, this.httpHelperService.getHttpOptions());
	}

	resetPassword(passwordResetModel: ResetUserPasswordModel) {
		return this.http.post<any>(`${API_URL}user/resetpassword`, passwordResetModel, this.httpHelperService.getHttpOptions());
	}

	sendInvitation(sendInvitationModel: SendInvitationModel) {
		return this.http.post<any>(`${API_URL}user/sendInvitation`, sendInvitationModel, this.httpHelperService.getHttpOptions());
	}
}
