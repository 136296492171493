import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHelperService } from './httphelper.service';
import { EventConfiguration } from '../model/EventConfiguration';
import { DeviceSettingsConfigurationsDTO } from '../model/DeviceSettingsConfigurationsDTO';
import { DeviceSetting } from '../model/DeviceSetting';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs';
import { ConfigurationTemplate, EventTypeConfiguration, SettingEventTypeConfiguration } from '../model/residentEventConfiguration/eventTypeConfiguration';

export const API_URL = environment.apiUrl;

@Injectable()
export class EventConfigurationService {
	constructor(public router: Router, private http: HttpClient, private httpHelperService: HttpHelperService) {
	}

	getCareHomeEventConfigurations(id: string = null): Observable<SettingEventTypeConfiguration> {
		var url = `${API_URL}EventConfiguration/GetCareHomeConfigurations`;

		if (id) {
			url = `${url}?careHomeId=${id}`;
		}

		return this.http
			.get<SettingEventTypeConfiguration>(url, this.httpHelperService.getHttpOptions())
			.pipe(map(response => response.body));
	}

	saveCareHomeEventConfigurations(eventConfiguration: SettingEventTypeConfiguration, id: string) {
		return this.http
			.post<any>(`${API_URL}EventConfiguration/SaveCareHomeConfigurations?careHomeId=${id}`, eventConfiguration, this.httpHelperService.getHttpOptions());
	}

	getConfigurationTemplates(): Observable<ConfigurationTemplate[]> {
		return this.http
			.get<ConfigurationTemplate[]>(`${API_URL}EventConfiguration/GetConfigurationTemplates`, this.httpHelperService.getHttpOptions())
			.pipe(map(response => response.body));
	}

	getConfigurationTemplate(id: string): Observable<SettingEventTypeConfiguration> {
		return this.http
			.get<SettingEventTypeConfiguration>(`${API_URL}EventConfiguration/GetConfigurationTemplate?templateId=${id}`, this.httpHelperService.getHttpOptions())
			.pipe(map(response => response.body));
	}

	saveConfigurationTemplates(eventConfiguration: SettingEventTypeConfiguration, id: string) {
		return this.http
			.post<any>(`${API_URL}EventConfiguration/SaveConfigurationTemplates?templateId=${id}`, eventConfiguration, this.httpHelperService.getHttpOptions());
	}

	addTemplate(templateName: string, referenceTemplateId: string = null) {
		let url = `${API_URL}EventConfiguration/AddTemplate?templateName=${templateName}`;

		if (referenceTemplateId) {
			url = `${url}&referenceTemplateId=${referenceTemplateId}`;
		}

		return this.http
			.post<any>(url, null, this.httpHelperService.getHttpOptions());
	}
}