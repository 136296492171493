import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EventNotficationMessage } from '../model/eventNotficationMessage';
import { AuthService } from '../services/auth.service'

@Injectable({
  providedIn: 'root'
})

export class SignalrService {
  private hubConnection: signalR.HubConnection
  eventNotficationMessage: BehaviorSubject<EventNotficationMessage>;
  reconnected: BehaviorSubject<string>;

  constructor(private authService: AuthService) {
    this.eventNotficationMessage = new BehaviorSubject<EventNotficationMessage>(null);
    this.reconnected = new BehaviorSubject<string>(null);
  }

  public startConnection = () => {
    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => {
        return this.authService.getToken();
      }
    }

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.workerUrl, options)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    this.hubConnection.onreconnected((connectionId: string) => {
      this.reconnected.next(connectionId);
    });

    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.error('Error while starting connection: ' + err));
  }

  public subscribeToEventsUpdate = () => {
    this.hubConnection.on('residentEvents', (message: EventNotficationMessage) => {
      this.eventNotficationMessage.next(message);
    });
  }

  public disconnect = () => {
    if (this.hubConnection) {
      this.hubConnection.off('residentEvents', () => { });
      return this.hubConnection.stop();
    }

    return Promise.resolve();
  }
}