import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import 'datatables.net';
import * as $ from 'jquery';

@Injectable()
export class DataTableService {
    constructor(private translate: TranslateService) {
    }

    getDataTableOptions(): DataTables.Settings {
        return {
            pagingType: 'full_numbers',
            language: this.getLanguage()
        };
    }

    getFirmwareVersionsOptions(): DataTables.Settings {
        return {
            lengthChange: false,
            pageLength: 3,
            searching: false,
            pagingType: 'full_numbers',
            language: this.getLanguage()
        };
    }

    getLanguage() {
        let res: any = {};
        res.paginate = {};

        this.translate.get("dataTable.search").subscribe((result: string) => {
            res.search = result;
        });

        this.translate.get("dataTable.info").subscribe((result: string) => {
            res.info = result;
        });

        this.translate.get("dataTable.lengthMenu").subscribe((result: string) => {
            res.lengthMenu = result;
        });

        this.translate.get("dataTable.paginate.first").subscribe((result: string) => {
            res.paginate.first = result;
        });

        this.translate.get("dataTable.paginate.previous").subscribe((result: string) => {
            res.paginate.previous = result;
        });

        this.translate.get("dataTable.paginate.next").subscribe((result: string) => {
            res.paginate.next = result;
        });

        this.translate.get("dataTable.paginate.last").subscribe((result: string) => {
            res.paginate.last = result;
        });

        this.translate.get("dataTable.zeroRecords").subscribe((result: string) => {
            res.zeroRecords = result;
        });
        
        return res;
    }
}

