import { Status } from '../model/status';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Diaper, DiaperBrand } from '../model/diaper';
import { HttpHelperService } from './httphelper.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class DiaperService {
  constructor(private httpClient: HttpClient, private httpHelperService: HttpHelperService) {
  }

  getDiaperList(status: Status) {
    return this.httpClient.get<Diaper[]>(API_URL + 'diaper/' + status, this.httpHelperService.getHttpOptions());
  }

  importDiaperList(formData) {
    return this.httpClient.post<any>(`${API_URL}diaper`, formData,
      {
        headers: this.httpHelperService.getHttpUploadHeader(),
        reportProgress: true,
        observe: 'events'
      }).pipe(
        map(event => event),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error.error);
  }

  getDiaperBrands() {
    return this.httpClient.get<DiaperBrand[]>(`${API_URL}diaper/brands`, this.httpHelperService.getHttpOptions());
  }

  getAccessibleDiaperBrands() {
    return this.httpClient.get<string[]>(`${API_URL}diaperbrand/` + this.httpHelperService.getCareHomeId(),
      this.httpHelperService.getHttpOptions());
  }

  updateAccessibleDiapeBrands(diaperBrands: DiaperBrand[]) {
    return this.httpClient.post<boolean>(`${API_URL}diaperbrand/` + this.httpHelperService.getCareHomeId(),
      diaperBrands, this.httpHelperService.getHttpOptions());
  }
}
