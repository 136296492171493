import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHelperService } from './httphelper.service';
import { TranslateService } from '@ngx-translate/core';
import { FirmwareVersion } from '../model/firmwareversion';
import { FirmwareUpdateSettings } from '../model/firmwareupdatesettings';
import { Status } from '../model/status';

const API_URL = environment.apiUrl;

@Injectable()
export class SettingsService {
    constructor(private http: HttpClient, private httpHelperService: HttpHelperService) {
    }

    getFirmwareVersions(status: Status) {
        return this.http.get<FirmwareVersion[]>(API_URL + 'settings/firmwareversions/' + status, this.httpHelperService.getHttpOptions());
    }

    addNewFirmwareVersion(firmwareVersion: FirmwareVersion) {
        return this.http.post<FirmwareVersion>(API_URL + 'settings', firmwareVersion, this.httpHelperService.getHttpOptions());
    }

    updateFirmwareVersion(firmwareVersion: FirmwareVersion) {
        return this.http.post<FirmwareVersion>(API_URL + 'settings/firmwareversion', firmwareVersion,
            this.httpHelperService.getHttpOptions());
    }

    getFirmwareUpdateSettings() {
        return this.http.get<FirmwareUpdateSettings>(API_URL + 'settings/firmwaresettings', this.httpHelperService.getHttpOptions());
    }

    updateFirmwareSettings(firmwareSettings: FirmwareUpdateSettings) {
        return this.http.post<boolean>(API_URL + 'settings/firmwaresettings', firmwareSettings, this.httpHelperService.getHttpOptions());
    }
}
