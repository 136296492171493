import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-privacy',
    templateUrl: './common-privacy.component.html',
    styleUrls: ['./common-privacy.component.scss']
})

export class PrivacyLayoutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void { }
}
