import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './shared/directives/alert.component';
import { AlertService } from './shared/services/alert.service';
import { AuthService } from './shared/services/auth.service';
import { AccountService } from './shared/services/account.service';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmEqualValidatorDerective } from './shared/directives/confim-equal-validator.derective';
import { PasswordPolicyValidatorDirective } from './shared/directives/password-policy-validator.derective';
import { AutoFocusDirective } from './shared/directives/auto-focus-directive';
import { SettingsService } from './shared/services/settings.service';
import { DataTableService } from './shared/services/datatable.service';
import { HttpHelperService } from './shared/services/httphelper.service';
import { ErrorService } from './shared/services/error.service';
import { ToastyModule } from 'ng2-toasty';
import { CareHomeService } from './shared/services/carehome.service';
import { DialogService } from './shared/services/dialog.service';
import { DiaperService } from './shared/services/diaper.service';
import { DepartmentService } from './shared/services/department.service';
import { ResidentService } from './shared/services/citizen.service';
import { UserLevelDirective } from './shared/directives/user-level.directive';
import { ProfileService } from './shared/services/ProfileService';
import { UserService } from './shared/services/user.service';
import { EventConfigurationService } from './shared/services/event-configuration.service';
import { NotificationService } from './shared/services/notification.service';
import { ServiceWorkerRegisteration } from './shared/services/service.worker.registeration';

@NgModule({
  imports: [
    CommonModule,

  ],
  declarations: [
    AlertComponent,
    ConfirmEqualValidatorDerective,
    PasswordPolicyValidatorDirective,
    AutoFocusDirective,
    UserLevelDirective
  ],
  exports: [
    AlertComponent,
    TranslateModule,
    ConfirmEqualValidatorDerective,
    PasswordPolicyValidatorDirective,
    ToastyModule,
    AutoFocusDirective,
    UserLevelDirective
  ],
  providers: [
    AlertService,
    AuthService,
    AccountService,
    DatePipe,
    SettingsService,
    DataTableService,
    HttpHelperService,
    ErrorService,
    CareHomeService,
    DialogService,
    DiaperService,
    DepartmentService,
    ResidentService,
    ProfileService,
    UserService,
    EventConfigurationService,
    NotificationService,
    ServiceWorkerRegisteration
  ]
})
export class SharedModule { }
