import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserBasicInfo } from "../model/user/UserBasicInfo";
import { environment } from '../../../environments/environment';
import { CareHomeDetail } from '../model/carehome';
import { HttpHelperService } from './httphelper.service';
import UserConfirmationModel from '../../modules/confirmation/user-confirmation/UserConfirmationModel';

export const API_URL = environment.apiUrl;

@Injectable()
export class AccountService {
    constructor(private http: HttpClient, private httpHelperService: HttpHelperService) {
    }

    confirmAccount(model: UserConfirmationModel) {
        return this.http.post<UserBasicInfo>(`${API_URL}account/confirmation`, model, this.httpHelperService.getHttpOptions());
    }

    getUserCareHomes(id?: string, username?: string) {
        return this.http.get<CareHomeDetail[]>(`${API_URL}account/usercarehomes?id=${id}&username=${username}`, this.httpHelperService.getHttpOptions());
    }
}